import { isEqual } from "lodash";
import moment from "moment";

export const convertRangeToDate = (
  range: string
): { startDate: Date | null; endDate: Date | null } => {
  const dayRegex = /\b\d+d\b/g;
  const currDate = moment.utc({ hour: 0 });
  const yesterDate = moment(currDate.subtract(1, "days"));
  const endDate = new Date(yesterDate.toDate()); // Set end date to yesterday
  let startDate = new Date(yesterDate.toDate());

  if (dayRegex.test(range)) {
    const diffDays = parseInt(range.split("d")[0]);
    startDate = moment(yesterDate)
      .subtract(diffDays - 1, "days")
      .toDate(); // Set end date to yesterday
    return { startDate, endDate };
  } else {
    return {
      startDate: null,
      endDate: null,
    };
  }
};

export const convertDateToRange = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): string => {
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return `${diffDays + 1}d`;
};

export const convertDateToDiffDays = ({
  startDate,
  endDate,
  weekifyString = false,
}: {
  startDate: Date;
  endDate: Date;
  weekifyString?: boolean;
}): string => {
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Check if both dates are Sundays
  const startDay = startDate.getDay();
  const endDay = endDate.getDay();
  const isStartMonday = startDay === 1;
  const isEndMonday = endDay === 1;
  const weeksApart = diffDays / 7;

  // If the range is from Sunday to Sunday and the difference is a multiple of 7
  if (
    isStartMonday &&
    isEndMonday &&
    weeksApart % 1 === 0 &&
    weeksApart !== 1
  ) {
    return weekifyString ? `${weeksApart} weeks` : `${weeksApart * 7} days`; // Return weeks
  } else {
    return `${diffDays} days`; // Return days
  }
};

export const addAbbreviationToNumber = (num: number, decimal: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(decimal).concat("B");
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(decimal).concat("M");
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(decimal).concat("K");
  }
  return num;
};

export function formatWeekRange(value: string) {
  const startDate = new Date(value);
  const endDate = new Date(value);
  endDate.setDate(startDate.getDate() + 6); // Add 6 days to get the end of the week

  const options = {
    month: "short",
    day: "numeric",
  } as Partial<Intl.DateTimeFormatOptions>; // Format options

  const startFormat = startDate.toLocaleDateString("en-US", options);
  const endFormat = endDate.toLocaleDateString("en-US", options);

  return `${startFormat}-${endFormat}`;
}

function areObjectsTypesAndKeysEquivalent(obj1: any, obj2: any): boolean {
  // Check if both inputs are objects
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if both objects have the same keys and types
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const type1 = typeof obj1[key];
    const type2 = typeof obj2[key];

    if (type1 !== type2) {
      return false;
    }
  }

  return true;
}

// eslint-disable-next-line complexity
function areObjectsEquivalent(obj1: any, obj2: any): boolean {
  // Check if both inputs are objects
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if both objects have the same keys
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
  }

  // Check if both objects have the same properties and types
  for (const key of keys1) {
    const type1 = typeof obj1[key];
    const type2 = typeof obj2[key];

    // Check the type of the property
    if (type1 !== type2) {
      return false;
    }

    // Check the value of the property
    if (!isEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function isValidArrayOfObjectsForTypeAndItsEmptyInstance<T>({
  arr,
  emptyInstance,
}: {
  arr: any[];
  emptyInstance: T;
}): boolean {
  if (!Array.isArray(arr)) {
    return false;
  }
  if (arr.length === 0) {
    return false;
  }
  return arr.reduce((acc, curr) => {
    const validType = areObjectsTypesAndKeysEquivalent(curr, emptyInstance);
    const validEmptyInstance = areObjectsEquivalent(curr, emptyInstance);
    return acc || (validType && !validEmptyInstance);
  }, false);
}

import { peerAnalyticsPublishType } from "src/types/analytics";

export const mosChartKey = "mos-chart";

export const videoLayerKeyAsPerLayers = (
  data: peerAnalyticsPublishType["video"]
) => {
  const videoLayers = Object.keys(data || []).map(key => {
    return simulcastVideoMap[key as keyof typeof simulcastVideoMap];
  });
  return videoLayers;
};

export const simulcastVideoMap = {
  f: "Video (High)",
  h: "Video (Medium)",
  q: "Video (Low)",
  null: "Video",
};

export const videoSimulcastMap = {
  "Video (High)": "f",
  "Video (Medium)": "h",
  "Video (Low)": "q",
  Video: "null",
};

import { get, set } from "lodash";
import { handleActions } from "redux-actions";
import {
  CLEAR_ROOM_DEBUGGER_DETAILS,
  CLEAR_ROOM_DEBUGGER_FILTER,
  DISABLE_LIVE_ROOM_DEBUGGER,
  ENABLE_LIVE_ROOM_DEBUGGER,
  FETCH_ANALYTICS_PEERS_DETAILS_DONE,
  FETCH_ANALYTICS_PEERS_DETAILS_FAIL,
  FETCH_ANALYTICS_PEERS_DETAILS_INIT,
  FETCH_ANALYTICS_PEERS_EVENT_LOGS_DONE,
  FETCH_ANALYTICS_PEERS_EVENT_LOGS_FAIL,
  FETCH_ANALYTICS_PEERS_EVENT_LOGS_INIT,
  FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE,
  FETCH_ANALYTICS_PEERS_WEBRTC_STATS_FAIL,
  FETCH_ANALYTICS_PEERS_WEBRTC_STATS_INIT,
  FETCH_PEER_ISSUES_DETAILS_DONE,
  FETCH_PEER_ISSUES_DETAILS_FAIL,
  FETCH_PEER_ISSUES_DETAILS_INIT,
  FETCH_PEER_PUBLISH_DETAILS_DONE,
  FETCH_PEER_PUBLISH_DETAILS_FAIL,
  FETCH_PEER_PUBLISH_DETAILS_INIT,
  FETCH_PEER_QUALITY_ISSUES_DETAILS_DONE,
  FETCH_PEER_QUALITY_ISSUES_DETAILS_FAIL,
  FETCH_PEER_QUALITY_ISSUES_DETAILS_INIT,
  FETCH_ROOM_DEBUGGER_DETAILS_DONE,
  FETCH_ROOM_DEBUGGER_DETAILS_FAIL,
  FETCH_ROOM_DEBUGGER_DETAILS_INIT,
  FETCH_ROOM_DEBUGGER_DETAILS_RESET,
  FETCH_USAGE_OVERVIEW_DONE,
  FETCH_USAGE_OVERVIEW_FAIL,
  FETCH_USAGE_OVERVIEW_INIT,
  FETCH_USAGE_OVERVIEW_RESET,
  MERGE_NEW_EVENTS_FETCHED,
  MERGE_NEW_ROOM_DEBUGGER_EVENTS,
  PATCH_ROOM_DEBUGGER_DETAILS_DONE,
  PATCH_ROOM_DEBUGGER_DETAILS_FAIL,
  PATCH_ROOM_DEBUGGER_DETAILS_INIT,
  RESET_ROOM_DEBUGGER_STATUS,
  SET_LIVE_ROOM_DEBUGGER_FLAG,
  SET_PEER_INSIGHTS_CHART_EVENTS,
  SET_PEER_INSIGHTS_DETAILS_DERIVED_STATES,
  UPDATE_ROOM_DEBUGGER_FILTER,
} from "./actions";
import { API_CALL_STATE } from "../../constants";

export const roomDebugger = handleActions(
  {
    [FETCH_ROOM_DEBUGGER_DETAILS_INIT]: state => ({
      ...state,
      roomDebuggerDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ROOM_DEBUGGER_DETAILS_DONE]: (state, action) => ({
      ...state,
      roomDebuggerDetailsStatus: API_CALL_STATE.DONE,
      roomDebuggerDetails: action.payload,
    }),
    [FETCH_ROOM_DEBUGGER_DETAILS_FAIL]: state => ({
      ...state,
      roomDebuggerDetailsStatus: API_CALL_STATE.FAILED,
    }),
    [PATCH_ROOM_DEBUGGER_DETAILS_INIT]: state => ({
      ...state,
      roomDebuggerDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [PATCH_ROOM_DEBUGGER_DETAILS_DONE]: (state, action) => {
      const { roomDebuggerDetails } = state;
      let { newEvent = [] } = roomDebuggerDetails;
      newEvent = [...action.payload.newEvent, ...newEvent];
      state.roomDebuggerDetails.nextPageLink =
        action?.payload?.nextPageLink || "";
      state.roomDebuggerDetails.newEvent = newEvent;

      state.roomDebuggerDetailsStatus = API_CALL_STATE.DONE;
      return state;
    },
    [PATCH_ROOM_DEBUGGER_DETAILS_FAIL]: state => ({
      ...state,
      roomDebuggerDetailsStatus: API_CALL_STATE.FAILED,
    }),

    [FETCH_ROOM_DEBUGGER_DETAILS_RESET]: state => ({
      ...state,
      roomDebuggerDetails: {
        event: [],
        newEvent: [],
        nextPageLink: "",
      },
      roomDebuggerDetailsStatus: "",
      analyticsDerivedStates: {
        liveEventsEnabled: false,
      },
    }),
    [RESET_ROOM_DEBUGGER_STATUS]: state => ({
      ...state,
      roomDebuggerDetailsStatus: "",
    }),
    [MERGE_NEW_ROOM_DEBUGGER_EVENTS]: state => ({
      ...state,
      roomDebuggerDetails: {
        ...state.roomDebuggerDetails,
        event: [
          ...state.roomDebuggerDetails.newEvent,
          ...state.roomDebuggerDetails.event,
        ],
        newEvent: [],
      },
    }),
    [SET_LIVE_ROOM_DEBUGGER_FLAG]: (state, action) => ({
      ...state,
      analyticsDerivedStates: {
        liveEventsEnabled: action.payload,
      },
    }),
    [ENABLE_LIVE_ROOM_DEBUGGER]: state => ({
      ...state,
      analyticsDerivedStates: {
        liveEventsEnabled: true,
      },
    }),
    [DISABLE_LIVE_ROOM_DEBUGGER]: state => ({
      ...state,
      analyticsDerivedStates: {
        liveEventsEnabled: false,
      },
    }),
    [UPDATE_ROOM_DEBUGGER_FILTER]: (state, action) => {
      const { key, value } = action.payload || {};
      if (!key) {
        return;
      }
      let updatedFilter = get(state, "roomDebuggerFilter");
      updatedFilter = set(updatedFilter, key, value);
      return {
        ...state,
        roomDebuggerFilter: updatedFilter,
      };
    },

    [CLEAR_ROOM_DEBUGGER_FILTER]: state => ({
      ...state,
      roomDebuggerFilter: {
        room_or_session_id: "",
        user_name: "",
        event_name: "",
        user_id: "",
        error_events: false,
      },
    }),
    [CLEAR_ROOM_DEBUGGER_DETAILS]: state => ({
      ...state,
      roomDebuggerDetailsStatus: "",
      roomDebuggerDetails: {
        event: [],
        newEvent: [],
        nextPageLink: "",
      },
    }),
    [MERGE_NEW_EVENTS_FETCHED]: state => {
      const { analyticsDerivedStates } = state;
      const { liveEventsEnabled } = analyticsDerivedStates;

      let { event = [], newEvent = [] } = state.roomDebuggerDetails;
      if (liveEventsEnabled) {
        state.roomDebuggerDetails.event = [...newEvent, ...event];
      } else {
        state.roomDebuggerDetails.event = [...event, ...newEvent];
      }

      state.roomDebuggerDetails.newEvent = [];
      return state;
    },
    [FETCH_USAGE_OVERVIEW_INIT]: state => {
      state.fetchUsageOverviewStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [FETCH_USAGE_OVERVIEW_DONE]: (state, action) => {
      state.fetchUsageOverviewStatus = API_CALL_STATE.DONE;
      state.usageOverview = action.payload;
      return { ...state };
    },
    [FETCH_USAGE_OVERVIEW_FAIL]: state => {
      state.fetchUsageOverviewStatus = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [FETCH_USAGE_OVERVIEW_RESET]: state => {
      state.fetchUsageOverviewStatus = "";
      return { ...state };
    },

    [FETCH_ANALYTICS_PEERS_EVENT_LOGS_DONE]: (state, action) => ({
      ...state,
      peerEventLogs: action.payload,
      fetchPeerAnalyticsEventLogsStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_ANALYTICS_PEERS_EVENT_LOGS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsEventLogsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ANALYTICS_PEERS_EVENT_LOGS_INIT]: state => ({
      ...state,
      fetchPeerAnalyticsEventLogsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ANALYTICS_PEERS_WEBRTC_STATS_DONE]: (state, action) => ({
      ...state,
      peerMosScore: action.payload.peerMosScoreAndQualityIssues,
      peerQualityIssues: {
        ...state.peerQualityIssues,
        ...action?.payload?.peerMosScoreAndQualityIssues?.issues,
      },
      peerSubscribeScore: action.payload.subscriberStats,
      fetchPeerAnalyticsPeerWebrtcStatsStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_ANALYTICS_PEERS_WEBRTC_STATS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsPeerWebrtcStatsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ANALYTICS_PEERS_WEBRTC_STATS_INIT]: state => ({
      ...state,
      peerMosScore: {},
      peerQualityIssues: {},
      peerSubscribeScore: {},
      fetchPeerAnalyticsPeerWebrtcStatsStatus: API_CALL_STATE.IN_PROGRESS,
    }),

    [FETCH_ANALYTICS_PEERS_DETAILS_INIT]: state => ({
      ...state,
      peerInsightsChartEvents: {},
      fetchPeerAnalyticsDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ANALYTICS_PEERS_DETAILS_DONE]: (state, action) => ({
      ...state,
      fetchPeerAnalyticsDetailsStatus: API_CALL_STATE.DONE,
      peerAnalyticsDetails: { ...action.payload },
    }),
    [FETCH_ANALYTICS_PEERS_DETAILS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsDetailsStatus: API_CALL_STATE.FAILED,
    }),

    [FETCH_PEER_PUBLISH_DETAILS_DONE]: (state, action) => ({
      ...state,
      peerPublishScore: action.payload,
      fetchPeerAnalyticsPublishScoreStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_PEER_PUBLISH_DETAILS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsPublishScoreStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_PEER_PUBLISH_DETAILS_INIT]: state => ({
      ...state,
      fetchPeerAnalyticsPublishScoreStatus: API_CALL_STATE.IN_PROGRESS,
    }),

    [FETCH_PEER_ISSUES_DETAILS_DONE]: (state, action) => ({
      ...state,
      peerIssueDetails: action.payload,
      fetchPeerAnalyticsIssueDetailsStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_PEER_ISSUES_DETAILS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsIssueDetailsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_PEER_ISSUES_DETAILS_INIT]: state => ({
      ...state,
      peerIssueDetails: {},
      fetchPeerAnalyticsIssueDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_PEER_QUALITY_ISSUES_DETAILS_DONE]: (state, action) => ({
      ...state,
      peerQualityIssues: { ...action.payload, ...state.peerQualityIssues },
      fetchPeerAnalyticsQualityIssueDetailsStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_PEER_QUALITY_ISSUES_DETAILS_FAIL]: state => ({
      ...state,
      fetchPeerAnalyticsQualityIssueDetailsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_PEER_QUALITY_ISSUES_DETAILS_INIT]: state => ({
      ...state,
      fetchPeerAnalyticsQualityIssueDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [SET_PEER_INSIGHTS_CHART_EVENTS]: (state, action) => ({
      ...state,
      peerInsightsChartEvents: action.payload,
    }),
    [SET_PEER_INSIGHTS_DETAILS_DERIVED_STATES]: (state, action) => ({
      ...state,
      peerInsightsDerivedStates: action.payload,
    }),
  },
  {
    roomDebuggerFilter: {
      room_or_session_id: "",
      user_name: "",
      event_name: "",
      user_id: "",
      error_events: false,
      limit: 100,
    },
    usageOverview: {},
    fetchUsageOverviewStatus: "",
    roomDebuggerDetails: {
      event: [],
      newEvent: [],
      nextPageLink: "",
      isLive: false,
    },
    peerInsightsDerivedStates: { subscribe: true },
    roomDebuggerDetailsStatus: "",
    analyticsDerivedStates: {
      liveEventsEnabled: false,
    },
    peerAnalyticsDetails: {},
    peerEventLogs: {},
    peerSubscribeScore: {},
    peerMosScore: {},
    peerPublishScore: {},
    peerIssueDetails: {},
    peerQualityIssues: {},
    fetchPeerAnalyticsPublishScoreStatus: "",
    fetchPeerAnalyticsDetailsStatus: "",
    fetchPeerAnalyticsEventLogsStatus: "",
    fetchPeerAnalyticsPeerWebrtcStatsStatus: "",
    fetchPeerAnalyticsIssueDetailsStatus: "",
    peerInsightsChartEvents: {},
    fetchPeerAnalyticsQualityIssueDetailsStatus: "",
  }
);
